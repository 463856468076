import React, { useState } from "react";
import { Container, FormHelperText, Grid } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { IoMdMail } from "react-icons/io";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";

const HomePage = () => {
  const { handleSubmit, control, formState: { errors } } = useForm({});
  const qualityimag = [
    { imag: "/images/palmshell1.png" },
    { imag: "/images/palmshell2.png" },
    { imag: " /images/Palm-Kernel-Shell-3.png" },
    { imag: "/images/palm-kernel-Carbon-shell-4.png" },
  ];

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setIsNavCollapsed(true);
    }
  };
  const [showFullContent, setShowFullContent] = useState(false);
  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const [aboutFullContent, setAboutFullContent] = useState(false);
  const toggleaboutContent = () => {
    setAboutFullContent(!aboutFullContent);
  };
  const validateEmail = (value) => {
    if (value !== '') {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      const isValid = emailRegex.test(value);
      return isValid || 'Invalid email address';
    }
  };

  const onnewsSubmit = (data) => {
    console.log(data)
  }

  const oncontactsubmit = (data) => {
    console.log(data)
  }


  return (
    <div>
      <div>
        <header>
          <nav class="navbar navbar-expand-lg navbar-light nav-bg position-fixed" style={{ width: "100%", zIndex: 9999 }}>
            <a class="navbar-brand" href="home">
              <img src="./images/nav logo.png" alt="Logo" className="Nav-logo img-fluid" href="#home" />

            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={!isNavCollapsed ? "true" : "false"} aria-label="Toggle navigation" onClick={() => setIsNavCollapsed(!isNavCollapsed)}>
              <span class="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"}`}
              id="navbarTogglerDemo02"
              style={{ justifyContent: "end" }}
            >
              <ul class="navbar-nav ">
                <li class="nav-item active" onClick={() => scrollToSection("banner")} ><a class="nav-link" href="#Home">Home</a></li>
                <li class="nav-item" onClick={() => scrollToSection("about")}><a class="nav-link" href="#About">About</a></li>
                <li class="nav-item" onClick={() => scrollToSection("products")}> <a class="nav-link" href="#Product"> Product</a></li>
                <li class="nav-item" onClick={() => scrollToSection("contact")}><a class="nav-link" href="#Contact"> Contact Us </a> </li>
              </ul>
            </div>
          </nav>
        </header>
        <div className="banner-bg" id="banner">
          <div className="banner-position">
            <div className="banner-content">
              <h1>Explore our eco-friendly Palm Shell products</h1>
              <p>
                Embrace Sustainability with Our Eco-Friendly Palm Shell
                Products! Discover Versatile Solutions for Biofuels,
                Agriculture, and Handicrafts. Explore Responsible Innovation for
                a Greener Tomorrow.
              </p>
              <button className="shop-now">Shop Now</button>
            </div>
          </div>
        </div>

        <div class="about" id="about">
          <div class="container">
              <Grid  container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div class="abt-main-tit">
                  <h1 className="mt-5">About us</h1>
                  {aboutFullContent ? (
                    <>
                      <p><b>Alam Perdana</b>, a renowned business entity operating in Malaysia, has established a strong presence in the industry alongside its sister concern, Mahar Vahana Resources. With a decade-long legacy of distributing high-quality palm shell products across Malaysia, Alam Perdana is now poised to expand its services globally. Leveraging its wealth of experience and driven by a vision for excellence, the company is committed to delivering exceptional services at competitive prices, solidifying its position as a trusted player in the international market.</p>
                      <p>Alam Perdana's strategic expansion plans are supported by a robust supply chain and a dedicated team of professionals who bring expertise and a deep understanding of the market dynamics. This enables the company to respond swiftly to the evolving needs of its global clientele, ensuring timely delivery and unparalleled service.</p>
                      <p>As Alam Perdana steps onto the global stage, it remains dedicated to building lasting relationships with partners and clients worldwide. The company's unwavering focus on quality, sustainability, and customer satisfaction positions it as a leading force in the industry, ready to take on new challenges and opportunities in the international arena.</p>
                      <p><b>Mahar Vahana Resources,</b> a sister concern of the esteemed Alam Perdana, is recognized as a dynamic and leading domestic supplier in Malaysia with more than 10 years of experience. Renowned for its unwavering commitment to excellence and reliability, Mahar Vahana specializes in the supply of high-quality palm shells. Catering to a multitude of prominent companies across Malaysia, the firm ensures the highest service and product quality standards, consistently meeting and exceeding client expectations. By leveraging its extensive industry knowledge and strong network of connections, Mahar Vahana Resources has positioned itself as a pivotal player in the market. The company's dedication to sustainability and innovation drives its operations, contributing significantly to the energy, agriculture, and industrial sectors. Through its expert handling and distribution of palm shells, Mahar Vahana Resources not only supports the diverse needs of its clients but also promotes eco-friendly practices and renewable energy solutions. With a focus on continuous improvement and customer satisfaction, Mahar Vahana Resources remains at the forefront of the industry, committed to delivering exceptional value and fostering long-term partnerships.</p>
                      <p>Leveraging its robust industry connections and profound understanding of the Malaysian market, Mahar Vahana Resources plays a pivotal role in supporting the diverse and evolving needs of businesses across the nation. Its strategic approach and dedication to sustainability make it an indispensable partner in the supply chain, contributing significantly to the operational success and efficiency of its clientele. By prioritizing customer satisfaction and adhering to stringent quality benchmarks, Mahar Vahana continues to foster strong, long-term relationships and drive industry growth in Malaysia.</p>
                      <p>As a key player in the Malaysian market, Mahar Vahana Resources is well-positioned to continue its trajectory of growth and success, further strengthening its role as a trusted partner in the industry. With a focus on innovation, sustainability, and customer satisfaction, Mahar Vahana is set to lead the way in driving progress and excellence within the supply chain sector.</p>
                      <div className="uses-btn">
                      <button className="read_more" onClick={toggleaboutContent}>
                        Read Less
                      </button>
                      </div>
                    </>
                  ) : (<>
                    <p>  <b>Alam Perdana</b>, a renowned business entity operating in Malaysia,
                      has established a strong presence in the industry alongside its sister
                      concern, Mahar Vahana Resources. With a decade-long legacy of distributing
                      high-quality palm Shells products across Malaysia, Alam Perdana is now poised
                      to expand its services globally. Leveraging its wealth of experience and
                      driven by a vision for excellence, the company is committed to delivering
                      exceptional services at competitive prices, solidifying its position as a
                      trusted player in the international
                      market.</p>
                      <div className="uses-btn">
                    <button className="read_more" onClick={toggleaboutContent}>
                      Read More
                    </button>
                    </div>
                  </>)}
                </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div class="about_img text_align_center">
                  <img class="img_responsive" src="images/about.png" alt="Palm-shell-img" className="img-fluid " />
                </div>
                </Grid>
              </Grid>
              </div>        
        </div>
        <div class="about mt-5" id="about">
          <div class="container">
              <Grid  container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div class="about_img text_align_center">
                  <img class="img_responsive" src="./images/palm1.jpg" alt="Palm-shell-img" className="img-fluid uses-imag" />
                </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div class="abt-main-tit">
                  <h1>Uses of Palm Shell</h1>
                  <p>
                    Palm shells, a byproduct of the palm oil industry, have a
                    variety of applications across different sectors due to
                    their unique properties. Here are some notable uses of palm
                    shells:
                  </p>
                  {showFullContent ? (
                    <>
                      <h5>Biomass Fuel:</h5>
                      <p>
                        Palm shells are widely used as a biomass fuel in power
                        plants and industrial boilers. They have a high
                        calorific value, making them an efficient and renewable
                        energy source. They are also used in the production of
                        charcoal and activated carbon, which is used in water
                        and air purification processes.
                      </p>
                      <h5>Agriculture:</h5>
                      <p>
                        Palm shells can be processed into organic fertilizers
                        and soil conditioners. They help improve soil structure,
                        water retention, and nutrient content. They are also
                        used as mulch to suppress weed growth and retain soil
                        moisture.
                      </p>
                      <h5>Construction Material:</h5>
                      <p>
                        Palm shells can be processed into organic fertilizers
                        and soil conditioners. They help improve soil structure,
                        water retention, and nutrient content. They are also
                        used as mulch to suppress weed growth and retain soil
                        moisture.
                      </p>

                      <h5>Animal Feed:</h5>
                      <p>
                        Processed palm shells can be used as a component in
                        animal feed, providing a source of fiber and nutrients.
                      </p>
                      <h5>Activated Carbon Production:</h5>
                      <p>
                        Palm shells are an excellent raw material for producing
                        activated carbon, which is used in air and water
                        filtration systems, as well as in industrial processes
                        to remove contaminants and impurities.
                      </p>
                      <h5>Biochar:</h5>
                      <p>
                        Palm shells can be converted into biochar, a form of
                        charcoal used to enhance soil fertility, sequester
                        carbon, and improve water retention in soils.
                      </p>
                      <h5>Energy Generation:</h5>
                      <p>
                        Palm shells are used in cogeneration plants where they
                        are burned to produce both electricity and heat,
                        contributing to energy efficiency and sustainability.
                      </p>
                      <h5>Crafts and Art:</h5>
                      <p>
                        In some regions, palm shells are used in traditional
                        crafts and artworks. They are carved, polished, and used
                        to make decorative items and jewelry.
                      </p>
                      <h5>Industrial Applications: </h5>
                      <p>
                        Palm shell ash is used in the manufacture of cement and
                        ceramics. The silica content in the ash improves the
                        quality and durability of these products. They are also
                        used as a blasting medium in abrasive blasting processes
                        for cleaning and surface preparation. The versatility of
                        palm shells underscores their importance as a
                        sustainable and eco-friendly resource, contributing to
                        various industries while promoting environmental
                        conservation.
                      </p>
                      <div className="uses-btn">
                      <button className="read_more" onClick={toggleContent}>
                        Show Less
                      </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <h5>Biomass Fuel:</h5>
                      <p>
                        Palm shells are widely used as a biomass fuel in power
                        plants and industrial boilers. They have a high
                        calorific value, making them an efficient and renewable
                        energy source. They are also used in the production of
                        charcoal and activated carbon, which is used in water
                        and air purification processes.
                      </p>
                      <h5>Agriculture:</h5>
                      <p>
                        Palm shells can be processed into organic fertilizers
                        and soil conditioners. They help improve soil structure,
                        water retention, and nutrient content. They are also
                        used as mulch to suppress weed growth and retain soil
                        moisture.
                      </p>
                      <h5>Construction Material:</h5>
                      <p>
                        Palm shells can be processed into organic fertilizers
                        and soil conditioners. They help improve soil structure,
                        water retention, and nutrient content. They are also
                        used as mulch to suppress weed growth and retain soil
                        moisture.
                      </p>
                      <h5>Animal Feed:</h5>
                      <p>
                        Processed palm shells can be used as a component in
                        animal feed, providing a source of fiber and nutrients.
                      </p>
                      <div className="uses-btn">
                        <button className="read_more" onClick={toggleContent}>
                          Show More
                        </button>
                      </div>
                    </>
                  )}
                </div>
                </Grid>
              </Grid>
          </div>
        </div>
      </div>
      <div className="quality-oil-bg">
        <Container>
          <div className="quality-title" id="products">
            <h1>We Supply</h1>
            <p>
              Discover the versatility and sustainability of our palm shell
              products. Derived from the outer layer of palm fruit kernels,
              these eco-friendly alternatives are abundant and renewable,
              offering a range of applications from biofuels to agricultural
              solutions and exquisite handicrafts. Explore how we harness
              natural resources responsibly to create innovative,
              environmentally conscious products for a greener future.
            </p>
          </div>
          <div className="pb-5">
            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
              {qualityimag &&
                qualityimag.map((a, i) => (
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={i}>
                    <div className="quality-box">
                      <img src={a.imag} alt="oil" className="palm-imag" />
                    </div>
                  </Grid>
                ))}
            </Grid>
            <div className="quality-actions pb-5">
              <button className="read_more ">See More</button>
            </div>
          </div>
          <div class="contact">
            <div class="container">
              <div class="titlepage text-center" id="contact">
                <h1>Contact Us</h1>
              </div>

              <form className="main_form" onSubmit={handleSubmit(oncontactsubmit)}>
                <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                    <Controller name="name" control={control} defaultValue="" rules={{ required: 'Name is required' }}
                      render={({ field }) =>
                        <input {...field} className="contactus" placeholder="Full Name *" type="text" name=" Name" />} />
                    <FormHelperText className='text-white'>{errors.name && errors.name.message}</FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller name="email" control={control} defaultValue="" rules={{ required: 'Email is required', validate: validateEmail }}
                      render={({ field }) =>
                        <input {...field} className="contactus" placeholder="Email *" type="email" name="Email" />} />
                    <FormHelperText className='text-white'>{errors.email && errors.email.message}</FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Controller name="phoneNumber" control={control} defaultValue=""
                      rules={{
                        required: 'Phone Number is required', maxLength: {
                          value: 10,
                          message: "Enter a Valid Number",
                        },
                        minLength: {
                          value: 10,
                          message: "Enter a Valid Number",
                        },
                      }}
                      render={({ field }) =>
                        <input {...field} className="contactus" placeholder="Phone Number *" type="text" name="Phone Number" />} />
                    <FormHelperText className='text-white'>{errors.phoneNumber && errors.phoneNumber.message}</FormHelperText>

                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                    {/* <textarea className="textarea" placeholder="Message" type="type"></textarea> */}
                    <Controller name="message" control={control} defaultValue="" rules={{ required: 'Message is required' }}
                      render={({ field }) =>
                        <textarea {...field} className="textarea" placeholder="Message *" type="text"></textarea>} />
                    <FormHelperText className='text-white'>{errors.message && errors.message.message}</FormHelperText>
                  </Grid>
                </Grid>
                <div className="quality-actions pb-5">
                  <button className="read_more1">SEND</button>
                </div>
              </form>
            </div>
          </div>
          <Grid container direction="row" alignItems="flex-start" justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div className="infoma">
                <h2>About</h2>
                <p className="ipsum">
                  Mahar Vahana Resources, a sister concern of the esteemed Alam Perdana, is recognized as a dynamic and leading domestic supplier in Malaysia. Renowned for its unwavering commitment to excellence and reliability, Mahar Vahana specializes in the supply of high-quality palm shells.</p>
                <ul class="social_icon">
                  <li><FaFacebookF /> </li>
                  <li><FaInstagram /></li>
                  <li> <FaXTwitter /></li>
                  <li><FaYoutube /></li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div class="infoma">
                <h2>Address</h2>
                <ul class="conta">
                  <li>No: 2, Jalan Vervea 4 Vervea,<br /> Bandar Cassia,<br /> Pulau , Pinang</li>
                  {/* <li><span className="mr-2"><IoMdMail/></span>alamperdana.my@gmail.com</li> */}
                  <li >
                    <a href="mailto:alamperdana.my@gmail.com" target="_blank" rel="noopener noreferrer" className="mail_links">
                      <span className="mr-2"><IoMdMail /></span>alamperdana.my@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
              <div class="infoma ml-lg-5" >
                <h2>Links</h2>
                <ul class="fullink">
                  <li onClick={() => scrollToSection("Home")}>Home</li>
                  <li onClick={() => scrollToSection("about")}>About</li>
                  <li onClick={() => scrollToSection("products")}>Products</li>
                  <li onClick={() => scrollToSection("contact")}>Contact</li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <div class="infoma">
                <h2>Newsletter</h2>
                <form className="form_subscri" onSubmit={handleSubmit(onnewsSubmit)}>
                  <div >
                    <div class="news-letters">
                      <Controller name="newlname" control={control} defaultValue=""
                        render={({ field }) =>
                          <input {...field} className="newsl" placeholder="Your Name" type="text" name="Your Name" />} />
                      <p className='text-news-white'>{errors.newlname && errors.newlname.message}</p>
                    </div>
                    <div>
                      <Controller name="newsemail" control={control} defaultValue="" rules={{ validate: validateEmail }}
                        render={({ field }) =>
                          <input {...field} className="newsl" placeholder="Email" type="email" name="Email" />} />
                      <p className='text-news-white'>{errors.newsemail && errors.newsemail.message}</p>
                    </div>
                    <div >
                      <button className="subsci_btn" >Subscribe</button>
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default HomePage;
