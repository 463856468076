import './App.css';
import HomePage from './components/HomePage';

function App() {
  return (
    <div >
  <HomePage/>
    </div>
  );
}

export default App;
